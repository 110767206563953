@import '/src/app/theme.scss';

.logo {
  min-width: 81px;
  display: flex;
  justify-content: flex-start;
  margin: auto 50px auto 0;
}

.menuLabel {
  // ovveride default antd styles;
  color: #50515b !important;
  font-weight: 400 !important;
  font-family: 'Ubuntu';
  letter-spacing: 0.16px !important;
}

.headerContainer {
  height: auto !important;
  background-color: white !important;
  display: flex;
  line-height: 80px !important;
  padding-inline: 0 !important;
  padding-left: 40px !important;

  @media (max-width: $mobile) {
    padding-left: 10px !important;
  }
}

.dividerLogo {
  height: 45px;
  margin-right: 20px;
}

.logoClinic {
  display: flex;
  justify-content: center;
  margin: auto 0;

  svg {
    margin-right: 91px;
  }
}

.LabelsWrapper {
  margin-right: 40px;
  span {
    color: #50515b;
    font-weight: 400;
    font-family: 'Ubuntu';
    letter-spacing: 0.16px;
    line-height: 24px;
  }
}

.LinkLabelsWrapper {
  margin-right: 40px;
  &:hover {
    color: #6266a7 !important;
  }
}

.LinkLabelsWrapperActive {
  color: #6266a7 !important;
}

.inputPrefix {
  color: #6266a7;
  svg {
    width: 17.4px;
    height: 17.4px;
  }
}

.userInfoContainer {
  display: flex;
  max-height: 80px;
  gap: 8px;
  margin-left: auto;
  border-radius: 6px;
  min-width: 178px;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: $mobile) {
    min-width: 50px;
  }

  &:hover {
    // background: rgba(98, 102, 167, 0.08);
    cursor: pointer;
  }

  div {
    display: flex !important;
  }

  li {
    display: flex !important;

    &:hover {
      background: rgba(98, 102, 167, 0.08);
    }
  }
}

.Menu {
  width: 100%;
  justify-content: flex-end;
}

.userDescription {
  color: #6266a7;
}

.userAvatar {
  background-color: rgba(98, 102, 167, 0.16);
  color: #50515b;
  align-self: center;
  margin-left: 8px;

  span {
    font-weight: 500;
    font-size: 16px;
    font-family: 'Ubuntu';
    letter-spacing: 0.16px;
  }
}

.userNameContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  justify-content: center;
  margin-left: auto;

  span {
    align-self: flex-end;
  }
}

.linksContainer {
  min-width: 260px;

  @media (max-width: $mobile) {
    margin-left: 50px;
    min-width: 100px;
  }

  a {
    color: $text-primary;
  }
}

.widgetLinksContainer {
  min-width: 600px;

  @media (max-width: $mobile) {
    margin-left: 50px;
    min-width: 100px;
  }

  a {
    color: $text-primary;
  }
}

.headerSplitter {
  background: $text-primary;
  min-width: 1px;
  height: 50px;
  display: flex;
  margin-top: 15px;
  margin-right: 30px;
}

.breadcrambsWrapper {
  min-height: 36px;
  background-color: $white;
  border: 0.5px solid $border;
  display: flex;
  align-items: center;

  li {
    display: flex;
    align-items: center;
  }
}

.separatorIcon {
  margin: 0 24px;
  display: flex;
}

.navBarContainer {
  //max-height: 117px;
  height: auto;
}

.siderMenu {
  background-color: transparent;
  border-inline-end: transparent !important;

  span {
    font-weight: 300;
    font-size: 18px;
    font-family: 'Ubuntu';
    letter-spacing: -2%;
    color: $text-primary;
  }

  li {
    padding-left: 40px !important;
    margin-bottom: 20px !important;
  }
}

.siderWrapper {
  background-color: #f9f9fc !important;
  margin-top: 32px;
}

.triggerButton {
  display: flex;
  align-items: center;
  border-top: 0.5px solid $border;
  height: 120px;
  width: 226px;

  span {
    color: #50515b;
    font-weight: 400;
    font-family: 'Ubuntu';
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  svg {
    margin-right: 12px;
    margin-left: 44px;
  }
}

:global {
  .ant-layout-sider-trigger {
    background: #f9f9fc !important;
    bottom: auto !important;
    margin-top: 40px;
  }
}

.triggerCollapsedButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.5px solid #dadada;
  height: 120px;
  width: 120px;
}

:global {
  .ant-layout-has-sider {
    background: transparent;
  }
}

:global {
  .ant-layout-footer {
    padding: 0 !important;
    height: 106px;
  }

  .ant-layout-sider-children {
    height: 280px !important;
  }

  .ant-layout-sider-trigger {
    position: static !important;
  }

  .ant-layout-has-sider {
    background-color: #f9f9fc !important;
  }
}

.userSubMenuTitle {
  display: flex;
}

:global {
  .ant-menu-submenu-open:after,
  .ant-menu-submenu-selected:after,
  .ant-menu-submenu:hover::after {
    border-bottom-color: #ffffff !important;
    border: none !important;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    background-color: transparent !important;
    span {
      font-weight: 400;
    }
  }

  /* .ant-menu-item-active {
    &:hover {
      svg {
        path {
          fill: #6266a7;
          opacity: 0.95;
        }
      }
    }
  }*/
}

.activeBreadcrumb {
  a {
    color: #bebfc8;
  }
}

.inactiveBreadcrumb {
  a {
    color: #6266a7;
  }
}

@media (max-width: $mobile) {
  .linksContainer,
  .widgetLinksContainer,
  .headerSplitter,
  .userInfoContainer {
    display: none;
  }

  .burgerMenuIcon {
    display: block;
    margin-left: auto;
    margin-right: 20px;
  }
}

@media (min-width: $mobile) {
  .burgerMenuIcon {
    display: none;
  }
}
