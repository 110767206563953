@import '/src/app/theme.scss';

.footerContainer {
  background-color: $primary;
  min-height: 106px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: #ffffff;

  a, span {
    color: #ffffff;
  }

  @media (max-width: $laptop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    justify-content: center;
    padding: 16px;
  }

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
    justify-content: flex-start;
  }
}
